.contact {
    width: 600px;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
}
.contact h4, .contact h3 {
    font-weight: 500;
    color: #ccc;
}
.telFooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
}
.linksFooter a:nth-child(1n + 2) {
padding-left: 15px;
}
.linksFooter img:hover {
    border-radius: 20px;
    box-shadow: -4px 4px 3px #bababa, 
    4px 4px 3px #ccc, 
    4px -4px 3px #ccc,
    -4px -4px 3px #ccc;
}