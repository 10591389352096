.telFooter {
    margin-top: 20px;
}
.telFooter a{
    color: #ccc;
}
.telFooter a:hover {
    color: #fff;
}
.socials {
  width: 50px;
  height: 50px;
    color: #ccc;
}
.footer {
    width: 100%;
    height: 40px;
    background-color:  #171616;
    text-align: center;
    color: #686868;
    padding-top: 5px;
}
.closed {
    display: none;
}
.footer img {
   position: relative;
   top: 6px;
   left: 0;
}
.whatssuptitle {
    position: fixed;
    bottom: 0;
    right: 0;
    scale: 1.2;
    z-index: 20;
}
@media screen and (max-width: 600px) {
    .whatssuptitle {
        position: fixed;
        bottom: 0;
        right: 0;
        scale: 1;
        z-index: 20;
    } 
}