.service__items {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 20px;
}
.service-item {
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 15px;
}
.service-item h2 {
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(1, 82, 175);
}
.main-services-price {
  font-size: 20px;
  font-style: italic;
  padding-bottom: 20px;
  color: rgb(3, 95, 199);
}
.main__services-img img {
  width: 100%;
}
@media (max-width: 1102px) {
  .service__items {
    gap: 15px;
  }
  .service-item .service-h2 {
    font-size: 20px;
  }
  .main-services-price {
    font-size: 20px;
  }
  .main__services-img img {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .service__items {
    gap: 5px;
  }
  .service-item .service-h2 {
    font-size: 16px;
  }
  .main-services-price {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .main__services-img img {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .service__items {
    gap: 5px;
  }
  .service-item .service-h2 {
    font-size: 14px;
  }
  .main-services-price {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .main__services-img img {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .service__items {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
  }
  .service-item {
    margin: 15px;
  }
  .main__services-img img {
    width: 100%;
  }
  .service-item .service-h2 {
    font-size: 20px;
    margin: 0 auto;
  }
  .main-services-price {
    font-size: 18px;
    padding-bottom: 5px;
  }
}
