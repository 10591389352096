@media screen and (max-width: 1400px) {
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 1100px;
  }
  .container {
    max-width: 1100px;
  }
  .containerFour h2 {
    width: 632px;
    margin: 0 0 42px 230px;
  }
  .generalContact {
    width: 1100px;
  }
  .check {
    margin-left: 35px;
  }

  nav ul {
    width: 780px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 130px;
    text-transform: uppercase;
  }
  header ul li a {
    font-weight: 500;
    color: #000000;
    font-size: 23px;
  }
  .links {
    margin-right: 300px;
  }
  .links img:hover {
    border-radius: 20px;
    box-shadow: -4px 4px 5px #ccc, 4px 4px 5px #ccc, 4px -4px 5px #ccc,
      -4px -4px 5px #ccc;
  }
  .containerThree {
    height: 750px;
  }
  .linksFooter img:hover {
    border-radius: 20px;
    box-shadow: -4px 4px 5px #ccc, 4px 4px 5px #ccc, 4px -4px 5px #ccc,
      -4px -4px 5px #ccc;
  }
}
@media screen and (max-width: 1102px) {
  .logo {
    gap: 20px;
  }
  .karcher {
    scale: 1.5;
    height: 35px;
    display: flex;
    align-self: center;
  }
  nav ul {
    width: 620px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 0px;
    text-transform: uppercase;
  }
  header ul li a {
    font-weight: 500;
    color: #000000;
    font-size: 20px;
  }
  .links {
    margin-right: 200px;
  }

  .second_screen h2 {
    max-width: 900px;
  }
  .containerThree {
    height: 700px;
  }
  .containerThree h2 {
    width: 900px;
  }
  .containerThree h3 {
    width: 900px;
  }
  .four_screen {
    padding-bottom: 5px;
  }
  .langTel {
    width: 885px;
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
  }
  .navBtn {
    width: 765px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .second_screen h2,
  .containerThree h2 {
    font-size: 45px;
  }
  .second_screen h3,
  .containerThree h3 {
    padding-top: 60px;
  }
  .form {
    position: absolute;
    top: 20%;
    left: 32%;
    margin: 0 0 10px 10px;
  }
  form {
    position: absolute;
    top: 5%;
    left: 25%;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 900px;
  }
  .choose {
    width: 870px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 275px;
  }
  .choose h4 {
    margin: -10px auto 15px;
  }
  .choose p {
    margin: 0 auto;
    width: 194px;
  }
  .galley {
    width: 880px;
  }
  figure img {
    width: 205px;
  }
  .containerFour h2 {
    width: 632px;
    margin: 0 0 42px 130px;
  }
  figure figcaption {
    margin-left: 25px;
  }
  .general_img {
    width: 900px;
  }
  .block_1,
  .block_2 {
    width: 430px;
  }
  .block_1 img,
  .block_2 img {
    width: 350px;
    height: 200px;
  }
  .block {
    margin-left: 85px;
  }
  .check {
    margin-left: 15px;
  }
  .general_img .check {
    margin: 20px 0 0 115px;
  }
  .general_img p {
    font-size: 16px;
  }
  .generalContact {
    width: 900px;
  }
  .containerFive {
    height: 170px;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: -50px;
    left: 0;
  }
  .contact {
    width: 650px;
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
  }
  .telFooter {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 450px;
  }
}
.second_screen .h2 {
  margin: 20px auto 55px;
  font-weight: 600;
  font-size: 25px;
}

@media screen and (max-width: 912px) {
  .en {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 50px;
    height: 40px;
  }
  .select {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 50px;
    height: 35px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navBtn {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .login {
    height: 45px;
    padding: 5px 0 10px 0;
    flex-basis: 25%;
    margin-left: 300px;
  }
  #fullMenu {
    display: none;
  }
  .links {
    margin-right: 80px;
  }
  .burger_menu_btn {
    position: absolute;
    right: 20px;
    top: 90px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
    cursor: pointer;
  }
  .burger_menu_btn span {
    position: absolute;
    width: 35px;
    height: 4px;
    background-color: #000;
    top: 25px;
    left: 0px;
    transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
  }
  .burger_menu_btn span:nth-child(1) {
    transform: translateY(-10px);
  }
  .burger_menu_btn span:nth-child(3) {
    transform: translateY(10px);
  }

  .menu {
    display: flex;
    flex-flow: column wrap;
    padding: 10px 10px 20px 0px;
    position: absolute;
    top: 80px;
    right: 0px;
    background-color: #0099ff;
    width: 275px;
    transition: 0.5s;
    transform: translate(255px);
    visibility: hidden;
  }
  .menu li {
    padding-top: 35px;
    font-size: 20px;
    padding-left: 10px;
    margin-left: 30px;
  }
  .menu li a {
    color: #fff;
    font-size: 20px;
  }
  .menu li:nth-child(1) {
    padding-top: 60px;
  }
  .wrap.open .menu {
    z-index: 2;
    transform: translateX(19px);
    visibility: visible;
  }
  .wrap.open .burger_menu_btn span:nth-child(1) {
    transform: translateY(0) rotate(45deg);
  }
  .wrap.open .burger_menu_btn span:nth-child(2) {
    opacity: 0;
  }
  .wrap.open .burger_menu_btn span:nth-child(3) {
    transform: translateY(0) rotate(-45deg);
  }
  .wrap.open .burger_menu_btn span {
    background-color: #fff;
  }
  .four_screen {
    padding-bottom: 80px;
  }
  .containerThree h2 {
    width: 600px;
  }
  .containerThree h3 {
    padding-top: 30px;
    width: 500px;
    margin: 0 auto;
  }
  .langTel {
    width: 790px;
    display: flex;
    justify-content: flex-end;
    padding-top: 35px;
  }
  .form {
    position: absolute;
    top: 10%;
    left: 30%;
    margin: 0 0 10px 10px;
  }
  form {
    position: absolute;
    top: 5%;
    left: 15%;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 700px;
  }
  .containerThree {
    height: 620px;
  }
  .containerFour {
    height: 720px;
  }
  nav ul {
    width: 320px;
    margin-left: 90px;
  }
  header ul li a {
    font-size: 18px;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 54px;
    font-size: 20px;
    margin-left: 130px;
  }
  .user span {
    font-size: 18px;
  }
  .loginExit {
    font-size: 15px;
    font-weight: 600;
  }
  .second_screen .container {
    padding-bottom: 20px;
  }
  .second_screen h3 {
    padding-top: 30px;
  }
  /* .second_screen h2 {
    width: 600px;
    font-size: 32px;
    margin: 10px auto 0;
    line-height: 1.2;
  } */
  .choose {
    margin-top: 120px;
    width: 700px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 220px;
    height: 220px;
  }
  .choose h4 {
    margin-top: -25px;
  }
  .choose p {
    margin: auto 0;
  }
  .galley {
    display: flex;
    justify-content: space-around;
    width: 700px;
    margin: 0;
  }
  figure {
    width: 200px;
  }
  figure figcaption {
    width: 140px;
    height: 70px;
    margin-left: 18px;
    margin-bottom: -30px;
    font-size: 16px;
  }
  .containerThree h2 {
    width: 632px;
    font-weight: 600;
    font-size: 32px;
    margin: 20px auto 40px;
  }
  figure img {
    width: 150px;
    border-radius: 10px;
    border: 1px solid green;
  }
  .containerFour .toArticleH3 {
    padding-top: 30px;
  }
  .containerFour h2 {
    width: 532px;
    margin: 0 0 32px 80px;
    font-size: 32px;
  }
  .general_img {
    display: flex;
    justify-content: space-between;
    width: 700px;
  }
  .block {
    width: 200px;
    height: 90px;
    margin-left: 80px;
    margin-bottom: -10px;
    border-radius: 5px;
  }
  .general_img .block h3 {
    width: 200px;
    font-size: 18px;
    padding-top: 20px;
  }
  .block_1 img,
  .block_2 img {
    width: 270px;
    height: 210px;
  }
  .block_1,
  .block_2 {
    width: 345px;
  }
  .general_img p {
    font-size: 15px;
  }
  .general_img .check {
    margin: 20px auto 0;
  }
  .generalContact {
    display: flex;
    justify-content: space-between;
  }
  .general_img .block_2 h3 {
    padding-top: 30px;
  }
  .contact {
    width: 650px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: 0px;
    left: 0;
  }
  .linkQuick ul {
    width: 220px;
  }
  .linkQuick ul a {
    font-size: 15px;
  }
  .contact h4 {
    font-size: 14px;
  }
  .contact h3 {
    font-size: 15px;
  }
  .telFooter a {
    font-size: 14px;
  }
  .telFooter img {
    position: relative;
    top: 5px;
    left: 0;
    transform: scale(0.9);
  }
  .telFooter {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 450px;
  }
  .footer {
    width: 100%;
    height: 30px;
    font-size: 13px;
    padding-top: 5px;
  }
  .containerFive {
    height: 250px;
  }
  .show {
    display: block;
  }
  .body_wrapper {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .second_screen h1 {
    width: 700px;
    font-weight: 600;
    font-size: 40px;
    color: #333333;
    margin: 20px auto 78px;
    line-height: 1.3;
  }
  .containerThree {
    height: 550px;
  }
  .generalContact {
    display: flex;
    justify-content: space-between;
  }
  .galley img {
    height: 180px;
  }
  .logo {
    gap: 70px;
  }
  .karcher {
    scale: 2.2;
    height: 35px;
    display: flex;
    align-self: center;
  }
}
@media screen and (max-width: 820px) {
  .links {
    margin-right: 40px;
  }
  .langTel {
    width: 750px;
    display: flex;
    justify-content: flex-end;
    padding-top: 35px;
  }
  .user button {
    display: inline-block;
    width: 90px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding-top: 12px;
  }
  figure figcaption {
    width: 140px;
    height: 70px;
    margin-left: 18px;
    margin-bottom: -30px;
    font-size: 14px;
  }
  .galley img {
    height: 180px;
  }

  .login {
    height: 45px;
    padding: 5px 0 10px 0;
    flex-basis: 20%;
    margin-left: 150px;
  }

  .burger_menu_btn {
    position: absolute;
    right: 55px;
    top: 90px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: 0px;
    left: -200px;
  }
  .contact {
    width: 570px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }
  .telFooter {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 370px;
  }
}
@media screen and (max-width: 750px) {
  .burger_menu_btn {
    position: absolute;
    right: 35px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
}
@media screen and (max-width: 700px) {
  .second_screen .h3 {
    font-size: 35px;
  }

  .second_screen .h1 {
    font-size: 25px;
  }
  .second_screen .h2 {
    font-size: 17px;
    margin: 20px auto 20px;
  }
  .socialplanwork {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-right: 50px;
  }
  .second_screen h1 {
    width: 500px;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    margin: 20px auto 78px;
    line-height: 1.3;
  }
  .four_screen {
    padding-bottom: 60px;
  }
  .langTel {
    width: 590px;
    display: flex;
    justify-content: flex-end;
  }
  .navBtn {
    width: 390px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    position: absolute;
    top: 5%;
    left: 15%;
    width: 400px;
    height: 410px;
  }
  form h2 {
    font-size: 20px;
    width: 280px;
    margin: 40px 0 15px 70px;
  }
  input,
  .btn {
    width: 330px;
    height: 55px;
    margin: 15px auto;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 500px;
  }
  .form {
    position: absolute;
    top: 10%;
    left: 18%;
    margin: 0 0 10px 10px;
  }
  .logo {
    position: relative;
    top: -5px;
    left: 0px;
    gap: 50px;
  }
  .logo img {
    transform: scale(0.7);
  }
  .karcher {
    scale: 2.2;
    height: 45px;
    display: flex;
    align-self: center;
  }
  .containerThree {
    height: 520px;
  }
  .containerFour {
    height: 1300px;
  }
  .containerFive {
    height: 180px;
  }
  header {
    width: 380px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav ul {
    width: 250px;
    margin-left: 30px;
  }
  header ul li a {
    font-size: 14px;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 54px;
    margin-left: 200px;
  }
  .user span {
    font-size: 16px;
  }
  .loginExit {
    font-size: 13px;
    font-weight: 600;
  }
  .login {
    flex-basis: 35%;
    height: 40px;
    font-size: 14px;
    padding: 2px 0 5px 3px;
  }
  .burger_menu_btn {
    position: absolute;
    right: 70px;
    top: 90px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
  /* .second_screen h2 {
    width: 500px;
    font-size: 25px;
  } */
  .fix_1 img,
  .fix_2 img,
  .fix_3 img {
    width: 70px;
    height: 70px;
    position: relative;
    top: -30px;
    left: 0;
  }
  .choose {
    margin-top: 70px;
    width: 480px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 150px;
    height: 150px;
  }
  .choose h4 {
    height: 40px;
    font-size: 14px;
    margin-top: -10px;
    padding: 0 10px 0 10px;
  }
  .choose p {
    width: 130px;
    font-size: 14px;
    margin: auto 0;
  }
  .check {
    width: 150px;
    height: 44px;
  }
  .check a {
    font-size: 16px;
    padding: 10px;
  }
  .containerThree h2 {
    width: 532px;
    font-size: 25px;
    margin: 10px auto 50px;
  }
  .containerFour h2 {
    width: 432px;
    margin: 0 0 22px 30px;
    font-size: 25px;
  }
  .general_img {
    display: block;
    width: 400px;
  }
  .block_1,
  .block_2 {
    width: 420px;
    margin: 10px auto 0;
  }
  .block {
    margin-left: 110px;
  }
  .block_1 {
    margin-bottom: 20px;
  }
  .general_img .block_2 h3 {
    padding-top: 30px;
  }
  .generalContact {
    width: 550px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .contact {
    width: 410px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: -50px;
    left: 0;
  }
  .linkQuick ul a {
    font-size: 15px;
  }
  .contact h4 {
    font-size: 13px;
  }
  .contact h3 {
    font-size: 15px;
  }
  .telFooter img {
    position: relative;
    top: 5px;
    left: 0;
    transform: scale(0.9);
  }
  .galley .closed {
    display: none;
  }
  .galley {
    width: 500px;
  }
  figure figcaption {
    margin-left: 14px;
  }
  .telFooter {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    width: 270px;
  }
  .phoneFooter {
    position: relative;
    bottom: 10px;
    left: -10px;
  }
  .links {
    margin-right: 0px;
  }
  .links img {
    scale: 0.8;
  }
  .links a:nth-child(1n + 2) {
    margin-left: 2px;
  }
  .planwork {
    flex: 1;
    padding-right: 20px;
    padding-left: 20px;
  }
  .planwork img {
    padding-left: 30px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  .langTel {
    width: 555px;
    display: flex;
    justify-content: flex-end;
  }
  .planwork {
    padding-right: 15px;
    padding-left: 0;
  }
  .burger_menu_btn {
    position: absolute;
    right: 35px;
    top: 90px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: -50px;
    left: -20px;
  }
}
@media screen and (max-width: 547px) {
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 600px;
    height: 100px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 540px) {
  .second_screen .h3 {
    font-size: 25px;
  }
  .second_screen .h1 {
    font-size: 18px;
  }
  .second_screen .h2 {
    font-size: 15px;
  }
  .tel a span {
    font-size: 18px;
  }
  .tel {
    margin-top: 12px;
    margin-bottom: 7px;
  }
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 600px;
    height: 100px;
    margin: 0 auto;
  }
  .socialplanwork {
    display: flex;
    flex-flow: column wrap;
    margin-right: 0;
  }
  .links a:nth-child(1n + 2) {
    margin-left: 15px;
  }
  .planwork img {
    scale: 0.8;
    padding-left: 0px;
    padding-right: 5px;
  }
  .planwork {
    flex: 1 1;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 10px;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 54px;
    margin-left: 120px;
  }
  .linkQuick ul {
    display: block;
    width: 80px;
    position: relative;
    top: -50px;
    left: 0px;
  }
  .containerOne {
    position: relative;
  }
  .containerThree {
    height: 810px;
  }
  .navBtn {
    display: block;
    width: 150px;
  }
  .burger_menu_btn {
    position: absolute;
    right: 30px;
    top: 140px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
  .burger_menu_btn span {
    position: absolute;
    width: 35px;
    height: 4px;
    background-color: #000;
    top: 19px;
    left: 0px;
    transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
  }
  .burger_menu_btn span:nth-child(1) {
    transform: translateY(-10px);
  }
  .burger_menu_btn span:nth-child(3) {
    transform: translateY(10px);
  }
  .menu {
    padding: 10px 10px 20px 0px;
    position: absolute;
    top: 120px;
    right: 0px;
    background-color: #0099ff;
    width: 235px;
    transition: 0.5s;
    transform: translate(255px);
    visibility: hidden;
  }
  .menu li {
    width: 100%;
    padding-top: 25px;
    font-size: 20px;
    margin-left: 20px;
  }
  .menu li a {
    color: #fff;
    font-size: 16px;
  }
  .menu li:nth-child(1) {
    padding-top: 40px;
  }
  .wrap.open .menu {
    z-index: 2;
    transform: translateX(19px);
    visibility: visible;
  }
  .wrap.open .burger_menu_btn span:nth-child(1) {
    transform: translateY(0) rotate(45deg);
  }
  .wrap.open .burger_menu_btn span:nth-child(2) {
    opacity: 0;
  }
  .wrap.open .burger_menu_btn span:nth-child(3) {
    transform: translateY(0) rotate(-45deg);
  }
  .wrap.open .burger_menu_btn span {
    background-color: #fff;
  }
  form {
    position: absolute;
    top: 5%;
    left: 12%;
    width: 370px;
    height: 390px;
  }
  form h2 {
    font-size: 18px;
    margin-left: 50px;
  }
  .form {
    width: 300px;
    height: 240px;
    position: absolute;
    top: 10%;
    left: 19%;
  }
  .form input {
    margin: 25px auto 20px;
  }
  .form .btnCanelExit {
    margin: -10px 0 0 15px;
  }
  .form .btnCanelExit .btn {
    height: 40px;
  }
  #fullMenu {
    display: none;
  }
  .langTel {
    width: 480px;
    display: flex;
    justify-content: flex-end;
  }
  .logo {
    position: relative;
    top: -20px;
    left: 30px;
    width: 130px;
  }
  .links {
    margin-right: 50px;
  }
  .back-dropShortMenu {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
  nav {
    width: 100px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }
  nav ul {
    display: block;
    margin-left: 0;
    width: 90px;
  }
  ul li {
    width: 70px;
  }
  header {
    width: 290px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login {
    width: 92px;
    height: 38px;
    padding-top: 5px;
    margin-top: -20px;
    margin-left: 160px;
  }
  .second_screen h2 {
    font-size: 24px;
  }
  .choose {
    width: 445px;
  }
  .check {
    margin-left: 25px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 140px;
    height: 150px;
  }
  .fix_1 img,
  .fix_2 img,
  .fix_3 img {
    width: 70px;
    height: 70px;
    position: relative;
    top: -30px;
    left: 40px;
  }
  .choose h4 {
    width: 130px;
    font-size: 15px;
    margin-left: 10px;
    margin-right: auto;
  }
  .containerThree h2 {
    width: 500px;
    font-size: 24px;
  }
  .galley {
    display: flex;
    margin-left: 70px;
    flex-flow: row wrap;
    margin-left: 0px;
  }
  .galley_1,
  .galley_2 {
    flex-basis: 50%;
    margin-bottom: 30px;
  }
  figure img {
    margin-top: 10px;
    width: 190px;
  }
  .galley .show {
    display: block;
    margin-left: -30px;
  }
  figure figcaption {
    margin-left: 60px;
    margin-top: 10px;
  }
  .galley_3 figcaption {
    position: relative;
    top: -20px;
    left: 30px;
    margin-left: 5px;
  }
  .galley .show img {
    margin-top: 0;
  }
  .galley .show figcaption {
    position: relative;
    top: -30px;
    left: -30px;
    margin-top: 18px;
  }
  .containerThree .check {
    margin-left: 40px;
  }
  .general_img {
    height: 150px;
  }
  .contact {
    width: 370px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .footerH34 {
    width: 200px;
    margin-left: 10px;
  }
  .generalContact {
    width: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .show {
    display: block;
  }
  .body_wrapper {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 495px) {
  .links {
    margin-right: 30px;
  }
  .links a:nth-child(1n + 2) {
    margin-left: 5px;
  }
  .show {
    display: block;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 400px;
  }
  .langTel {
    width: 400px;
    display: flex;
    justify-content: flex-end;
  }
  header {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    position: absolute;
    top: 5%;
    left: 6%;
    width: 360px;
    height: 390px;
  }
  .form {
    width: 300px;
    height: 240px;
    position: absolute;
    top: 10%;
    left: 14%;
  }
  .menu {
    position: absolute;
    width: 245px;
    top: 110px;
    right: -30px;
  }
  .login {
    /* color: #000; */
    width: 92px;
    margin-left: 120px;
  }
  .second_screen h1 {
    width: 400px;
    font-weight: 600;
    font-size: 25px;
    color: #333333;
    margin: 20px auto 78px;
    line-height: 1.3;
  }
  .logo {
    position: relative;
    top: -25px;
    left: 10px;
    width: 130px;
    gap: 10px;
  }
  .burger_menu_btn {
    position: absolute;
    right: 10px;
    top: 127px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
    background-color: transparent;
  }
  .planwork {
    margin-left: 30px;
  }
  .socialplanwork {
    display: flex;
    flex-flow: column wrap;
    margin-right: 10px;
  }
  .planwork span {
    font-size: 12px;
  }
  .planwork img {
    position: relative;
    top: 7px;
    left: 0;
    scale: 0.7;
  }
  .containerOne {
    height: 200px;
  }
  .logo img {
    transform: scale(0.6);
  }
  .choose {
    width: 390px;
  }
  .choose h4 {
    margin-left: 3px;
    margin-top: -15px;
    padding: 0 1px 0 1px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 125px;
    height: 140px;
  }
  .fix_1 img,
  .fix_2 img,
  .fix_3 img {
    width: 60px;
    height: 60px;
  }
  .second_screen h3 {
    font-size: 16px;
  }
  .second_screen h2 {
    /* width: 350px; */
    font-size: 18px;
  }
  .containerThree h3 {
    width: 300px;
    font-size: 18px;
  }
  .containerThree h2 {
    width: 400px;
    font-size: 20px;
  }
  .containerFour .toArticleH3 {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .containerFour h2 {
    width: 300px;
    margin: 0 auto 20px;
    font-size: 20px;
  }
  .block_1,
  .block_2 {
    width: 390px;
    margin: 10px;
    margin-left: 5px;
  }
  .block {
    margin-left: 100px;
  }
  .contact {
    display: block;
  }
  .generalContact {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .telFooter {
    display: inline;
    padding-left: 10px;
  }
  .footerH34 {
    display: block;
    margin-top: -60px;
    margin-bottom: 10px;
  }
  .contact h3 {
    padding-bottom: 20px;
  }

  .galley {
    width: 420px;
    display: flex;
    flex-flow: row wrap;
    margin-left: -10px;
  }
  .galley .galley_1,
  .galley .galley_3 {
    margin-left: 0px;
  }
  .galley .show {
    margin-left: 5px;
  }
  figure figcaption {
    margin-left: 35px;
    margin-top: 10px;
  }
  .galley .show figcaption {
    position: relative;
    top: -30px;
    left: -5px;
    margin-top: 20px;
  }
  .containerThree {
    height: 800px;
  }
  .contact {
    margin-left: 10px;
  }
  .footer {
    width: 100%;
    height: 30px;
    font-size: 13px;
    padding-top: 0px;
  }
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 465px;
    height: 100px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 400px) {
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 400px;
    height: 100px;
    margin: 0 auto;
  }

  .burger_menu_btn {
    position: absolute;
    right: 10px;
    top: 120px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: 3;
  }
  .tel a span {
    font-size: 16px;
  }
  .links {
    margin-right: 15px;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 54px;
    margin-left: 60px;
  }
  figure figcaption {
    width: 140px;
    height: 70px;
    margin-left: 18px;
    margin-bottom: -40px;
    font-size: 14px;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 350px;
  }
  form {
    position: absolute;
    top: 5%;
    left: 8%;
    width: 300px;
    height: 390px;
  }
  form h2 {
    font-size: 16px;
    margin-left: 10px;
  }
  input,
  .btn {
    width: 250px;
    height: 55px;
    margin: 15px auto;
  }
  .form {
    width: 300px;
    height: 240px;
    position: absolute;
    top: 10%;
    left: 8%;
  }
  .form input {
    margin: 25px auto 20px;
  }
  .form .btnCanelExit {
    margin: -10px 0 0 15px;
  }
  .form .btnCanelExit .btn {
    height: 40px;
  }
  .langTel {
    width: 340px;
    display: flex;
    justify-content: flex-end;
  }
  header {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login {
    /* color: #000; */
    width: 92px;
    margin-left: 80px;
  }
  .menu {
    position: absolute;
    top: 110px;
    right: -5px;
    width: 205px;
  }
  .logo {
    position: relative;
    top: -25px;
    left: 10px;
    width: 130px;
  }
  .choose {
    width: 340px;
  }
  .choose h4 {
    width: 100px;
    font-size: 12px;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    width: 105px;
    height: 130px;
  }
  .fix_1 img,
  .fix_2 img,
  .fix_3 img {
    position: relative;
    top: -30px;
    left: 25px;
  }
  .choose p {
    width: 100px;
    font-size: 13px;
    margin: auto 0;
  }
  .galley_1,
  .galley_2 {
    float: none;
  }
  .galley .closed {
    display: none;
  }
  .containerThree {
    height: 1220px;
  }
  .galley {
    width: 250px;
    margin: 0 auto;
  }
  figure img {
    width: 250px;
    height: 270px;
  }
  figure figcaption,
  .galley_3 figcaption {
    width: 150px;
    margin-left: 50px;
  }
  .containerThree h2 {
    width: 350px;
  }
  .block_1,
  .block_2 {
    width: 300px;
    margin: 0 auto 10px;
  }
  .block_1 img,
  .block_2 img {
    width: 280px;
  }
  .general_img {
    width: 350px;
  }
  .general_img .block h3 {
    width: 180px;
    font-size: 15px;
  }
  .block {
    margin-left: 50px;
  }
  .general_img p {
    padding: 8px 10px 25px 12px;
  }
  .generalContact {
    width: 350px;
  }
  .reg_btn {
    width: 170px;
  }
  .tel {
    padding-left: 2px;
  }
  .second_screen h1 {
    width: 330px;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    margin: 20px auto 78px;
    line-height: 1.3;
  }
  .second_screen .h2 {
    font-size: 14px;
  }
  .galley img {
    height: 230px;
  }
  .galley .galley_1,
  .galley .galley_2,
  .galley .galley_3 {
    margin-left: -45px;
  }
  .galley_3 figcaption {
    position: relative;
    top: -20px;
    left: 50px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 360px) {
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 360px;
    height: 100px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 345px) {
  .second_screen .h3 {
    font-size: 20px;
  }
  .second_screen .h1 {
    font-size: 15px;
  }
  .second_screen .h2 {
    font-size: 13px;
  }
  .tel a span {
    font-size: 18px;
  }
  .fixedlangTel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.3);
    width: 290px;
    height: 100px;
    margin: 0 auto;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 54px;
    margin-left: 0px;
  }
  .user button {
    display: inline-block;
    width: 50px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding-top: 12px;
  }
  .containerOne {
    height: 180px;
  }
  .logo {
    position: relative;
    top: -25px;
    left: 0px;
    width: 130px;
  }
  .links {
    margin-right: 0px;
  }
  .links a:nth-child(1n + 2) {
    margin-left: 0px;
  }
  .links img {
    scale: 0.6;
  }
  .tel {
    padding-left: 25px;
  }
  .containerOne,
  /* .container, */
  .containerThree,
  .containerFour,
  .containerFive {
    width: 280px;
  }

  header {
    width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .langTel {
    width: 280px;
    display: flex;
    justify-content: flex-end;
  }
  .socialplanwork {
    display: flex;
    flex-flow: column wrap;
    margin-right: 0;
  }
  .reg_btn {
    position: relative;
    top: 0;
    left: 20px;
    width: 130px;
    display: block;
    padding: 5px;
    margin-top: 10px;
    font-size: 13px;
  }
  .tel a span,
  .telFooter a span {
    font-size: 12px;
    padding-left: 0px;
  }
  .logo img {
    transform: scale(0.6);
  }
  .burger_menu_btn {
    position: absolute;
    right: 0px;
    top: 115px;
  }
  .second_screen h2 {
    /* width: 280px; */
    font-size: 14px;
  }
  .second_screen h3 {
    font-size: 12px;
  }
  .choose {
    width: 280px;
    display: block;
    margin: 0 auto;
  }

  .fix_1,
  .fix_2,
  .fix_3 {
    width: 200px;
    height: 180px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .fix_1 {
    margin-top: 50px;
  }
  .fix_1 img,
  .fix_2 img,
  .fix_3 img {
    position: relative;
    width: 80px;
    height: 80px;
    top: -30px;
    left: 55px;
  }
  .choose h4 {
    width: 200px;
    font-size: 14px;
  }
  .choose p {
    width: 200px;
  }
  .containerThree h2 {
    width: 280px;
    font-size: 18px;
  }
  .containerThree h3 {
    width: 280px;
    font-size: 14px;
  }
  .containerFour h2 {
    width: 280px;
    font-size: 18px;
  }
  .containerFour .toArticleH3 {
    font-size: 14px;
  }
  .general_img {
    width: 280px;
  }
  .block_1,
  .block_2 {
    width: 280px;
  }
  .block_1 img,
  .block_2 img {
    width: 270px;
  }
  .general_img p {
    font-size: 13px;
  }
  .generalContact {
    width: 280px;
  }
  .contact {
    width: 280px;
  }
  .footer {
    font-size: 10px;
  }
  .form {
    width: 200px;
    height: 230px;
    position: absolute;
    top: 10%;
    left: 8%;
  }
  .form input {
    margin: 25px auto 20px;
    width: 180px;
  }
  .form .btnCanelExit .btn {
    width: 70px;
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 100px;
    height: 54px;
  }
  form {
    position: absolute;
    top: 5%;
    left: 8%;
    width: 240px;
    height: 390px;
  }
  input,
  .btn {
    width: 180px;
    height: 55px;
    margin: 15px auto;
  }
  form h2 {
    width: 220px;
    font-size: 12px;
  }
  .btn {
    padding-left: 0;
  }
  .menu {
    position: absolute;
    top: 110px;
    right: -10px;
    width: 195px;
  }
  .login {
    width: 86px;
    margin-left: 20px;
    margin-top: -37px;
  }
  .second_screen h1 {
    width: 280px;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin: 20px auto 50px;
    line-height: 1.3;
  }
  .fix_1,
  .fix_2,
  .fix_3 {
    margin-bottom: 50px;
  }
  .fix_2 {
    margin-bottom: 30px;
  }
  .check {
    width: 130px;
    height: 44px;
    margin-left: 20px;
  }
  figure figcaption,
  .galley_3 figcaption {
    top: -12px;
  }
}
