.general_img {
    width: 1050px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.block_1, .block_2 {
    width: 500px;
    border: 1px solid green;
}
.block {
    position: relative;
    z-index: 1;
    width: 260px;
    height: 100px;
    background-color: #ffffff;
    margin-left: 120px;
    margin-bottom: -10px;
    border-radius: 5px;
}
.block_1 img, .block_2 img {
    width: 350px;
    height: 320px;
}
.general_img h3 {
    width: 240px;
    padding-top: 15px;
    text-align: center;
    margin: 5px auto;
    color: #024f82;
}
.general_img .block_2 h3 {
    padding-top: 30px;
}
.general_img p {
    text-indent: 30px;
    font-size: 18px;
    text-align: justify;
    margin-top: 15px;
    padding: 25px 10px 25px 12px;
    background-color: #ffffff;
    color: #024f82;
    border-radius: 20px;
    height: 200px;
    width: 100%;
}
.general_img .check {
    margin: 20px 0 0 140px;
}