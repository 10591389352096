.second_screen .container {
  text-align: center;
  padding-bottom: 40px;
}

.second_screen h3 {
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  padding-top: 80px;
}
.second_screen h1 {
  width: 932px;
  font-weight: 600;
  font-size: 45px;
  color: #333333;
  margin: 20px auto 78px;
  line-height: 1.3;
}
.check {
  width: 200px;
  height: 54px;
  background-color: #0099ff;
  border-radius: 5%;
  margin: 30px 0 0 215px;
}
.check a {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  font-size: 19px;
  padding: 14px;
}
.check:hover {
  background-color: #007acb;
}
