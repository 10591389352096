.navBtn {
  width: 1250px;
  display: flex;
  justify-content: center;
}
nav ul {
  width: 950px;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-left: 190px;
  text-transform: uppercase;
}
header ul li a {
  font-weight: 500;
  color: #000000;
  font-size: 24px;
}

.closed {
  display: none;
}
.active-link {
  background-color: #98b0bf;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.3s;
}
