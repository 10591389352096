.vectorup {
    transform: rotate(180deg);
}
.en {
    position: absolute;
    top: 43px;
    right: 10px;
    width: 50px;
    height: 40px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    z-index: 5;
    border-top: 1px solid#fff;
}
.en a {
    display: inline-block;
    color: #fff;
    width: 100%;
}
.select {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 50px;
    height: 38px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
}

.lang {
  margin-top: 2px;
}