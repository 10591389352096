.reviews {
  background-color: rgba(215, 241, 246, 0.34);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
.reviews__container {
  width: 60%;

  margin: 0 auto;
}
.reviews__title {
  font-size: 30px;
  padding: 40px 0px;
  text-transform: uppercase;
}
.review {
  background-color: rgb(222, 218, 187);
  background-color: rgb(229, 227, 218);
  padding: 50px;
  padding-bottom: 70px;
  padding-top: 70px;
  margin-bottom: 10px;
}
.review__img {
  border-radius: 50%;
}
.review__text {
  margin: 20px 0;
}
.review__link {
  display: inline-block;
  background-color: #c0cbd2;
  padding: 15px;
  border-radius: 15px;
  margin-top: 10px;
  color: #020142;
  transition: all 0.3s;
  font-weight: 700;
}
.review__link:hover {
  background-color: #01568a;
  color: #fff;
  transition: all 0.3s;
}
.mySwiper .swiper-pagination {
  position: absolute;
  bottom: 95%;
}
.kateryna {
  scale: 1.7;
  margin-bottom: 20px;
}
.kateryna-link {
  margin-top: 30px;
}
/* .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 300px;
  padding-bottom: 50px;
}

.swiper-slide img {
  display: block;
  width: 100%;

} */
@media (max-width: 600px) {
  .kateryna {
    width: 45%;
    margin-bottom: 20px;
  }
  .reviews__container {
    width: 90%;

    margin: 0 auto;
  }
  .review__img {
    border-radius: 50%;
    width: 35%;
  }
  .reviews {
    background-color: rgba(215, 241, 246, 0.34);
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (max-width: 450px) {
  .review .kateryna {
    width: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .review__text {
    font-size: 14px;
  }
  .review {
    padding: 30px;
  }
}
