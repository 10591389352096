.container-video {
  background-color: #e2f1fd;
}
.videoplayeryoute {
  margin: 10px;
  height: 300px;
}
.youtube_main-page {
  text-align: center;
  padding-bottom: 20px;
}
.youtube_main-page h3 {
  padding: 20px 0px;
}
