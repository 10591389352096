.first_screen {
    background-color: rgba(0, 153, 255, 0.2);
    background-image: url(../img/bg_header.jpg);
    background-repeat: repeat-y;
    background-size: 100%;
    background-position: top right;
}
.containerOne {
    width: 1400px;
    margin: 0 auto;
}
