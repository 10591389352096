.navBtn {
    width: 1250px;
    display: flex;
    justify-content: center;
}
nav ul {
    width: 950px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 190px;
    text-transform: uppercase;
}
header ul li a {
    font-weight: 500;
    color: #000000;
    font-size: 24px;
}
/* header ul li a:hover {
    border-bottom: 3px solid #0099FF;
} */
.closed {
    display: none;
}
.active-link-eng{
    /* background-color: #98b0bf; */
    background-color: #98b0bf;
    padding: 10px 35px;
    border-radius: 5px;
    transition: .3s;
}
