.lang select {
    position: absolute;
    top: 5px;
    right: 10px;
    border: 1px solid transparent;
    width: 50px;
    height: 30px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 10px;
    font-size: 15px;
}
.lang select option {
    color: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15px;
}
.lang {
  margin-top: 2px;
}