.logo {
  position: relative;
  top: 0px;
  left: 5px;
  display: flex;
  gap: 70px;
}
.logo img {
  width: 100px;
}
.karcher {
  scale: 2.2;
  height: 35px;
  display: flex;
  align-self: center;
}

@media screen and (max-width: 700px) {
  .logo {
    position: relative;
    top: 20px;
    left: 0px;
    /* width: 150px; */
  }
  .logo img {
    transform: scale(0.7);
  }
  /* .karcher {
    height: 50px;
    display: flex;
    align-self: flex-end;
  } */
}
