.containerFour {
    width: 1400px;
    height: 924px;
    margin: 0 auto;
    text-align: center;
}
.containerFour .toArticleH3 {
    padding: 80px 0 20px 0;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
}
.containerFour h2 {
    color: #333333;
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 42px 400px;
    width: 632px;
    line-height: 1.2;
}