.fixedlangTel {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  opacity: 1;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 65px;
  margin: 0 auto;
  right: 0;
}

.langTel {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
.lang {
  margin-top: -2px;
}

/* class Tel start */
.socialplanwork {
  display: flex;
  justify-content: flex-start;
  margin-right: 50px;
}
.links {
  margin-right: 200px;
}
.planwork {
  flex: 2;
  padding-right: 20px;
}
.planwork img {
  padding-right: 7px;
}
.links img:hover {
  border-radius: 20px;
  box-shadow: -4px 4px 5px #ccc, 4px 4px 5px #ccc, 4px -4px 5px #ccc,
    -4px -4px 5px #ccc;
}
.links a:nth-child(1n + 2) {
  margin-left: 20px;
}
.tel {
  margin-top: 12px;
}
.tel a {
  font-weight: 600;
  color: #000000;
}
.tel a:hover {
  color: #016cb2;
}
.tel img {
  scale: 1.2;
}
.tel a span {
  font-size: 20px;
}
.telFooter a span {
  font-size: 15px;
  padding-left: 5px;
}
.telSubmit {
  margin-right: 0px;
}
/* class Tel end */

/* class Submit start */
.reg_btn {
  position: relative;
  top: 0;
  left: 0px;
  background-color: #fff;
  color: #000;
  width: 200px;
  display: block;
  padding: 5px;
  margin-top: 15px;
  border: 1px solid #0099ff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 1px 1px 3px #0099ff, -1px -1px 3px #0099ff, -1px 1px 3px #0099ff,
    1px -1px 3px #0099ff;
}
.reg_btn:focus {
  background-color: #ccc;
}
/* class Submit end */
